<template>
  <div class="content content_in">
    <div class="title-row row justify-content-between">
      <div class="col-12 col-md-auto">
        <h1>{{ $t("directlink.title") }}</h1>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-item">
          <label class="form-item__label">{{ $t("name") }}</label>
          <input v-model="name" :class="{ invalid: $v.name.$error }" type="text" :placeholder="$t('name')" />
          <small v-if="$v.name.$error && (!$v.name.minLength || name === '')" class="error">{{ $t("min_len_pass", { number: "3" }) }}</small>
          <small v-if="$v.name.$error && !$v.name.maxLength" class="error">{{
            $t("max_len_pass", {
              number: 52,
            })
          }}</small>
        </div>
      </div>

      <div class="col-12 col-md-3 col-xl-2">
        <div class="form-item">
          <label class="form-item__label">{{ $t("type_platform") }}</label>
          <custom-multiselect v-model="type" :list="typeList" :allowEmpty="false">
            <template v-slot:block="props">
              <span>
                {{ props.value.title }}
              </span>
            </template>
            <template v-slot:list="props">
              <span>
                {{ props.value.title }}
              </span>
            </template>
          </custom-multiselect>
        </div>
      </div>

      <div class="col-12 col-md-3 col-xl-2 d-flex align-items-end">
        <div class="form-item">
          <button class="btn _w100" @click="createDirectlink" :disabled="isLoading">
            <img v-if="isLoading" src="@/assets/images/rolling-button.svg" class="loader-button" alt="loader" />
            {{ $t("create_link") }}
          </button>
        </div>
      </div>
    </div>

    <div v-if="directlinks.length" class="c-table mt-5">
      <tableAnimation />

      <div class="inner">
        <vue-good-table styleClass="table _no-wrap" :columns="columns" :rows="directlinks">
          <template slot="table-column" slot-scope="props">
            <span class="cursor-pointer"> {{ $t(props.column.label) }}: </span>
          </template>

          <template slot="table-row" slot-scope="props">
            <template v-if="props.column.field === 'id'">
              <span class="cursor-pointer" @click="goToStatistic(props.row.id)">
                {{ props.row.id }}
              </span>
            </template>

            <template v-else-if="props.column.field === 'link'">
              <a class="directlink-reference" :href="props.row.link" target="_blank">{{ props.row.link }}</a>
            </template>

            <template v-else-if="props.column.field === 'control'">
              <ul class="controls">
                <li>
                  <router-link :to="`/edit-platform/${props.row.id}/directlink`" :title="$t('platform.edit_platform')" class="controls__btn" :aria-label="$t('platform.edit_platform')">
                    <svg v-svg symbol="edit" size="0 0 14 15" role="info"></svg>
                  </router-link>
                </li>
                <li>
                  <button class="controls__btn" :title="$t('copy')" @click="copyLink(props.row.link)">
                    <svg v-svg symbol="copy" size="0 0 13 15" role="info"></svg>
                  </button>
                </li>
              </ul>
            </template>
          </template>
        </vue-good-table>
      </div>
    </div>

    <div v-else class="empty-state">
      <div v-if="!isLoading" class="empty-state__wrapper">
        <div class="empty-state__icon">
          <svg v-svg role="presentation" size="0 0 18 16" symbol="link"></svg>
        </div>
        <div class="empty-state__title">{{ $t("directlink.empty_state_title") }}</div>
        <p class="empty-state__text">{{ $t("directlink.empty_state_text") }}</p>
      </div>

      <template v-else>
        <img src="@/assets/images/rolling.svg" alt="loader" class="loader" />
      </template>
    </div>

    <div class="c-pagination">
      <ul class="pagination" v-if="directlinks && directlinks.length">
        <li class="page-item">
          <button @click.prevent="page--" :class="{ disabled: page <= 1 }" :disabled="page <= 1" class="page-link icon-prev prev" data-ci-pagination-page="2" rel="prev" :title="$t('statistic.prev')">
            <svg v-svg symbol="prev" size="0 0 8 14"></svg>
          </button>
        </li>
        <li class="page-item">
          <button @click.prevent="page++" :class="{ disabled: page * limit >= total }" :disabled="page * limit >= total" class="page-link icon-next next" data-ci-pagination-page="2" rel="next" :title="$t('statistic.next')">
            <svg v-svg symbol="next" size="0 0 8 14"></svg>
          </button>
        </li>
        <li class="page-item">{{ page === 1 ? page : limit * (page - 1) + 1 }} - {{ limit * (page - 1) + directlinks.length }} {{ $t("from") }} {{ total }}</li>
        <li>
          <custom-multiselect class="ml-2" v-model="limit" :list="optionsPagination">
            <template v-slot:block="props">
              <span>
                {{ props.value }}
              </span>
            </template>
            <template v-slot:list="props">
              <span>
                {{ props.value }}
              </span>
            </template>
          </custom-multiselect>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import "@/assets/css/_pagination.scss";
import statisticsService from "@/api/serviceStatistics";
// import subaccsService from "@/api/subaccsService";
import TableAnimation from "@/components/TableAnimation";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "directlink",
  beforeMount() {
    document.title = `Partners.House | ${this.$t("directlink.title")}`;
  },
  beforeUpdate() {
    document.title = `Partners.House | ${this.$t("directlink.title")}`;
  },

  data() {
    return {
      type: { title: "Adult", value: "Adult" },
      typeList: [
        { title: "Adult", value: "Adult" },
        { title: "Mainstream", value: "Mainstream" },
      ],
      columns: [
        {
          label: "platforms.table.id",
          field: "id",
          sortable: false,
        },
        {
          label: "platforms.table.name",
          field: "name",
          sortable: false,
        },
        {
          label: "platforms.table.type",
          field: "traffic_type",
          sortable: false,
        },
        {
          label: "platforms.table.reference",
          field: "link",
          sortable: false,
        },
        {
          label: "platforms.table.control",
          field: "control",
          width: "170px",
          thClass: "text-end",
          sortable: false,
        },
      ],
      name: "",
      directlinks: [],
      isLoading: false,
      page: 1,
      total: null,
      limit: 10,
      offset: null,
      optionsPagination: [10, 25, 50, 100],
    };
  },
  components: {
    TableAnimation,
  },
  mixins: [validationMixin],
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(52),
    },
  },
  methods: {
    createDirectlink() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.isLoading = true;

      const payload = {
        is_direct_link: 1,
        name: this.name,
        traffic_type: this.type.value,
      };

      statisticsService
        .setDirectlink(payload)
        .then((res) => {
          if (res && res.status === 200) {
            this.$alert({
              type: "success",
              title: this.$t("directlink.created"),
            });

            this.name = "";
            this.$v.$reset();
            this.getDirectlinks();
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getDirectlinks() {
      this.isLoading = true;

      let params = this.$route.query;
      const payload = {
        offset: params.offset ? params.offset : this.offset,
        limit: this.limit,
      };

      statisticsService
        .getDirectlinks(payload)
        .then((res) => {
          if (res && res.status === 200) {
            this.directlinks = res.data.subaccounts;
            this.total = res.data.total;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async copyLink(link) {
      try {
        await navigator.clipboard.writeText(link);

        this.$alert({
          title: this.$t("copied_succ"),
          type: "success",
        });
      } catch (error) {
        console.error(error.message);
      }
    },
    goToStatistic(id) {
      localStorage.setItem("statisticPlatform", id);
      this.$router.push("/statistic");
    },
  },
  watch: {
    $route: function () {
      const params = this.$route.query;
      this.offset = params.offset || 0;
      if (this.offset === 0) {
        this.page = 1;
      } else {
        this.page = this.offset / this.limit + 1;
      }
    },
    limit: function () {
      this.page = 1;
      localStorage.setItem("paginationLimit", this.limit);
      this.getDirectlinks();
    },
    offset: function () {
      this.getDirectlinks();
    },
    page: function () {
      if (this.page === 1 || this.page < 0) {
        this.offset = null;
        this.$router
          .push({
            path: "/directlink",
          })
          .catch(() => {});
      } else {
        this.offset = this.limit * (this.page - 1);
        this.$router
          .push({
            path: "/directlink",
            query: { offset: this.offset },
          })
          .catch(() => {});
      }
    },
  },
  mounted() {
    this.getDirectlinks();
  },
};
</script>
<style lang="scss" scoped>
.empty-state {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.empty-state__wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 380px;
  text-align: center;
}
.empty-state__icon {
  margin-bottom: 30px;

  svg {
    width: 44px;
    height: 44px;
    fill: #ddd;
  }
}
.empty-state__title {
  font-weight: 600;
  font-size: 18px;
}
.empty-state__text {
  margin-top: 10px;
  margin-bottom: 0;
  line-height: 24px;
  font-size: 14px;
  color: #717171;
}

.loader {
  width: 40px;
  height: 40px;
}

.directlink-reference {
  color: #317475;
}

.c-pagination {
  margin-top: 35px;
}

.content.content_in {
  padding-bottom: 150px;
}

.form-item {
  width: 100%;
  margin: 0;
  padding: 0;

  @media (max-width: 770px) {
    margin-bottom: 40px;
  }
}
::v-deep .text-end {
  text-align: end;
}
</style>
